
import { SelectField, TextField, Button } from '@aws-amplify/ui-react';
import { useState } from 'react';

export default function StoryForm(props) {

  const formPlaceholderText = {
    storyType: "fairytale",
    imageStyle: "disney",
    model: "claude",
    protagonistName: "Prince Flynn",
    protagonistDesc: "A prince with blonde hair and a fancy royal outfit",
    protagonistColour: "Blue",
    villainName: "Fluffy McFluffles",
    villainDesc: "A giant evil rabbit with pitch black fur and red eyes",
    villainColour: "Black",
    npcName: "Alice",
    npcDesc: "A beautiful fairy wearing a sparkling dress",
    npcColour: "Pink"
  }

  const [storyType, setStoryType] = useState(formPlaceholderText.storyType);
  const [imageStyle, setImageStyle] = useState(formPlaceholderText.imageStyle);
  const [model, setModel] = useState(formPlaceholderText.model);
  const [protagonistName, setProtagonistName] = useState(formPlaceholderText.protagonistName); 
  const [protagonistDesc, setProtagonistDesc] = useState(formPlaceholderText.protagonistDesc);
  const [protagonistColour, setProtagonistColour] = useState(formPlaceholderText.protagonistColour);
  const [villainName, setvillainName] = useState(formPlaceholderText.villainName);
  const [villainDesc, setvillainDesc] = useState(formPlaceholderText.villainDesc);
  const [villainColour, setvillainColour] = useState(formPlaceholderText.villainColour);
  const [npcName, setNpcName] = useState(formPlaceholderText.npcName);
  const [npcDesc, setNpcDesc] = useState(formPlaceholderText.npcDesc);
  const [npcColour, setNpcColour] = useState(formPlaceholderText.npcColour);

  function getFormData() {
    return {
      storyType,
      imageStyle,
      model,
      protagonistName,
      protagonistDesc,
      protagonistColour,
      villainName,
      villainDesc,
      villainColour,
      npcName,
      npcDesc,
      npcColour
    }
  }

  function getDisplayStatus(bool) {
    return bool ? "flex" : "none";
  }

  return (
    <div className="storyFormOverlay" style={{ display: getDisplayStatus(props.show)}}>
      <div className="storyFormContainer">
        <div className="storyFormCropper">
          <div className="storyLoading" style={{ display: getDisplayStatus(props.loading)}}>
            <h2>Writing and painting your storybook 🧙‍♂️✨</h2>
            <img className="spinning" src="/img/loading_icon.png" alt="Loading icon" />
          </div>
          <div className="storyForm" style={{ display: getDisplayStatus(!props.loading)}}>
            <h2>
              <img className="storyFormLogo" src="/img/storybook_logo.png" alt="GenAI Storybook" />
              GenAI Storybook
            </h2>
            <p>
              <strong>GenAI Storybook</strong> is a tool to help you create your own story. <br />
              Tell us what type of story you'd like to read:
            </p>
            <form>
              <fieldset>
                <legend>📖 Overall Story</legend>
                <SelectField name="storyType" label="What genre of story do you like?" defaultValue="fairytale" onChange={(e) => setStoryType(e.target.value)}>
                  <option value="fairytale" >Bedtime Fairytale</option>
                  <option value="adventure">Epic Adventure</option>
                  <option value="fantasy">High Fantasy</option>
                  <option value="crime">Noir Crime</option>
                </SelectField>
                <SelectField name="imageStyle" label="What style of pictures do you like?" defaultValue="disney" onChange={(e) => setImageStyle(e.target.value)}>
                  <option value="disney" >Cartoon</option>
                  <option value="gothic">Gothic</option>
                  <option value="monet">Monet</option>
                  <option value="van_gogh">Van Gogh</option>
                  <option value="photorealistic">Photorealistic</option>
                </SelectField>
                <SelectField name="model" label="Which text foundation model to use?" defaultValue="claude" onChange={(e) => setModel(e.target.value)}>
                  <option value="titan" disabled >Amazon Titan</option>
                  <option value="claude">Anthropic Claude</option>
                </SelectField>
              </fieldset>
              
              <fieldset>
                <legend>👑 Main Character</legend>
                <TextField name="protagonistName" label="What's the main character's name?" onChange={(e) => setProtagonistName(e.target.value)} placeholder={formPlaceholderText.protagonistName} />
                <TextField name="protagonistDesc" label="How would you describe the main character?" onChange={(e) => setProtagonistDesc(e.target.value)} placeholder={formPlaceholderText.protagonistDesc} />
                <TextField name="protagonistColour" label="What's the main character's favourite colour?" onChange={(e) => setProtagonistColour(e.target.value)} placeholder={formPlaceholderText.protagonistColour} />
              </fieldset>

              <fieldset>
                <legend>🧚‍♀️ Main Character's Friend</legend>
                <TextField name="npcName" label="What's the name of the main character's friend?" onChange={(e) => setNpcName(e.target.value)} placeholder={formPlaceholderText.npcName} />
                <TextField name="npcDesc" label="How would you describe the main character's friend?" onChange={(e) => setNpcDesc(e.target.value)} placeholder={formPlaceholderText.npcDesc} />
                <TextField name="npcColour" label="What's the main character's friend's favourite colour?" onChange={(e) => setNpcColour(e.target.value)} placeholder={formPlaceholderText.npcColour} />
              </fieldset>

              <fieldset>
                <legend>😈 Villain</legend>
                <TextField name="villainName" label="What's the villain's name?" onChange={(e) => setvillainName(e.target.value)} placeholder={formPlaceholderText.villainName} />
                <TextField name="villainDesc" label="How would you describe the villain?" onChange={(e) => setvillainDesc(e.target.value)} placeholder={formPlaceholderText.villainDesc} />
                <TextField name="villainColour" label="What's the villain's favourite colour?" onChange={(e) => setvillainColour(e.target.value)} placeholder={formPlaceholderText.villainColour} />
              </fieldset>
              <Button variation="primary" colorTheme="success" onClick={(e) => props.onclickHandler(getFormData())}>✨ Tell me a story! ✨</Button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}