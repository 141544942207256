import './App.css';
import { useState } from 'react';
import Book from './components/Book';
import StoryForm from './components/StoryForm';

function App() {
  const [storyFormVisibiity, setStoryFormVisibiity] = useState(true);
  const [storyLoadingVisibility, setStoryLoadingVisibility] = useState(false);
  const [storyFormData, setStoryFormData] = useState({});
  const [pageData, setPageData] = useState({});
  const [imageData, setImageData] = useState({});
  const [bookResponse, setBookResponse] = useState({});

  function matchPageLengths(pages, images) {
    const pageLength = Object.keys(pages).length;
    const imageLength = Object.keys(images).length;

    if (pageLength > imageLength) {
      let pagesArray = Object.entries(pages);
      pagesArray.length = imageLength;
      pages = Object.fromEntries(pagesArray);
    }
    if (pageLength < imageLength) {
      let imagesArray = Object.entries(images);
      imagesArray.length = pageLength;
      images = Object.fromEntries(imagesArray);
    }

    return [pages, images];
  }

  async function retrieveBookData(storyFormData) {
    const url = storyFormData.model == "titan" ? "https://ai-storybook-api.guymor.people.aws.dev/inputs" : "https://ai-storybook-api.guymor.people.aws.dev/input-claude";
    try {
      setStoryLoadingVisibility(true);
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(storyFormData),
      });
  
      const result = await response.json();
      console.log("Success:", result);

      setBookResponse(result);

      let pages = Object.fromEntries(Object.entries(result).filter(([key]) => key.includes('page')));
      let images = Object.fromEntries(Object.entries(result).filter(([key]) => key.includes('image')));
      
      [pages, images] = matchPageLengths(pages, images);

      console.log("pages", pages);
      console.log("images", images);

      setPageData(pages);
      setImageData(images);
      setStoryLoadingVisibility(false);
      setStoryFormVisibiity(false);
    } catch (error) {
      console.error("Error:", error);
    }
  }

  function storyFormOnclickHandler(formData) {
    console.log("form data", formData);
    setStoryFormData(formData);
    setStoryLoadingVisibility(true);
    retrieveBookData(formData);
  }

  function wait(delay){
    return new Promise((resolve) => setTimeout(resolve, delay));
  }

  async function fetchAndSetImages(url, elementId, retries, delay) {
    return fetch(url)
    .then(res => {
      if (res.ok) {
        res.text().then((result) => {
          document.getElementById(elementId).classList.add("hideSpinner");
          document.getElementById(elementId).style.backgroundImage = `url('data:image/png;base64,${result}')`;
          document.getElementById(elementId).style.backgroundSize = "600px 600px";
        });
        return res
      }
      if (retries > 0) {
        wait(delay).then(() => {
          return fetchAndSetImages(url, elementId, retries - 1, delay);
        })
      }
      throw new Error(res.status)
    })
    .catch(error => console.log("Image doesn't exist yet, keep waiting!"));
  }
  

  function loadImageUntilSuccess(elementId, imageNumber) {
    const imageQuery = `story_id=${bookResponse.story_id}&image_num=${imageNumber}`;
    wait(15000).then(() => {fetchAndSetImages(`https://ai-storybook-api.guymor.people.aws.dev/fetch?${imageQuery}`, elementId, 300, 1000)});
  }

  return (
    <div className="App">
      <div className="background">
        <img src="/img/castle.png" alt="Background" />
        <div className="backgroundStrip" />
      </div>
      <StoryForm 
        show={storyFormVisibiity}
        loading={storyLoadingVisibility}
        onclickHandler={storyFormOnclickHandler}
      />
      <Book>
        {(
          function createBookPages() {
            let bookPages = [];
            for (let i = 1; i <= Object.keys(pageData).length; i += 1) {
              const pageImgId = "page-img-" + i;

              bookPages.push(
                <div className="demoPage">
                  <div className="storyIllustBox" id={pageImgId} background={loadImageUntilSuccess(pageImgId, i)} >
                    {/* <img className="storyIllust" id={pageImgId} src= /> */}
                    <div className="storyIllustCaption">{imageData[`image_${i}`]["caption"]}</div>
                    {/* <img className="storyIllust spinning" id={pageImgId} src={loadImageUntilSuccess(pageImgId, i)} alt={imageData[`image_${i}`]["caption"]} /> */}
                  </div>
                </div>
              );
              bookPages.push(
                <div className="demoPage">
                    <div className="storyText">
                      <p>{pageData[`page_${i}`]}</p>
                    </div>
                </div>
              );
            }
            return bookPages;
          }
        )([], 0, 0)}
      </Book>
    </div>
  );
}

export default App;
