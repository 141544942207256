import HTMLFlipBook from "react-pageflip";

export default function Book(props) {
  return (
    <HTMLFlipBook
      className="book"
      width={600}
      height={600}
      maxShadowOpacity={0.5}
    >
      {props.children}
    </HTMLFlipBook>
  );
}